.roadmap-wrapper {
    width: 100%;
    background-color: #5762d5;
  }


.timeline {
  padding: 15px;
  display: grid;
  grid-template-columns: 2% auto;
  justify-content: center;
}

.tmul {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
}
.tmul li {
  margin-top: 30px;
  position: relative;
  padding: 25px;
}
.tmul li p {
  padding: 0 20px;
  transition: 1s;
  max-width: 300px;

}

.title {
  position: absolute;

  top: 21px;
  left: -50px;
  transition: 1s;
  font-size: 12px;
}
.timeline-line {
  background: #f50057;
  width: 4px;
  border-radius: 12px;
  position: relative;
  justify-self: start;
}

.timeline-point {
  border: none;
  position: absolute;

  border-radius: 50%;
  background: #f50057;
  width: 15px;
  height: 15px;

  top: 2rem;
  left: -0.9rem;
  transition: 1.5s ease;
}
.timeline-innerline {
  position: absolute;
  background: blue;
  width: 4px;
  height: 0%;

  top: 0%;
  left: 0%;

  transition: 1s linear;
}

@media screen and (min-width: 940px) {
  .timeline {
    display: block;
    margin-bottom: 1rem;
  }
  .tmul {
    flex-direction: row;
  }
  .tmul li {
    margin-top: 0px;
    position: relative;
    width: 100%;
    padding: 0px;
  }
  .tmul li p {
    transform: translateY(-10px);
    /* padding: 0 20px; */
    transition: 1.5s;
    text-align: center;
    max-height: 0;
  }

  .title {
    transition: 1.5s;
    font-size: 12px;

    position: absolute;
    top: -5rem;
    left: 40.5%;
  }

  .timeline-point {
    width: 15px;
    height: 15px;

    top: -2.5rem;
    left: 40%;
    transition: 1s ease;
  }

  .timeline-line {
    width: 100%;
    height: 4px;
  }

  .timeline-innerline {
    position: absolute;
    background: blue;
    width: 0%;
    height: 4px;

    top: 0%;
    left: 0%;

    transition: 1s linear;
    border-radius: 999px;
  }
}
