.stats-wrapper {
    width: 100%;
    background-color: #00061b;
  }


.py-16{
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
}
  