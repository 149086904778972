.custom-appbar{
    backdrop-filter: blur(10px);
    background-color: rgba(245, 245, 245, 0.93);
    color: transparent;
}

.custom-appbar.active{
    background-color: #5762d5;
    transition: .4s ease-in-out;
}

#nav-drawer{
    right: -300px !important;
    transition: .4s ease-in-out;
}

#nav-drawer.active{
    right: 0 !important;
}

#backdrop{
    display: none !important;
}

#backdrop.active{
    display: block !important;
}